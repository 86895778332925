// 공통으로 사용되는 스타일 컴포넌트 모음 파일입니다.
import styled from "styled-components";
import { Select } from "react-select";

//img
import Rightarrow from "Assets/images/icons/rightarrow.svg";
import Calendar from "Assets/images/icons/calendar.svg";
import Childrenimg from "Assets/images/icons/children.png";
import Talk from "Assets/images/icons/landing_talk.svg";

// Container
export const Container = styled.div`
  position: relative;
  /* margin-left: 220px; */
  /* top: 60px; */
  /* min-height: calc(100vh - 38px); */
  /* height: 100vh; */
  /* min-width: 1205px; */
  width: 100%;
  /* max-width: calc(100% - 220px); */
  /* padding: 40px 50px; */
  /* overflow-x: scroll; */
  input {
    height: 34px;
    width: 100%;
    border: 1px solid var(--grey4);
    padding-left: 12px;
    border-radius: 4px;
    font-size: 14px;
    ::placeholder {
      color: var(--grey3);
    }
  }
  input[type="checkbox"] {
    display: inline-block;
    width: 100px;
    opacity: 1;
    margin-right: 8px;
    accent-color: var(--grey1);
    position: static;
    vertical-align: middle;
  }
  input[type="checkbox" i] {
    width: 20px;
    height: 20px;
    margin-top: 0;
  }
  input[type="checkbox"]:checked {
    background-color: var(--grey1);
    color: var(--white);
  }
  label {
    margin-right: 20px;
    cursor: pointer;
    vertical-align: middle;
  }
  h2 {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 16px;
    line-height: 140%;
  }
  h3 {
    display: inline-block;
    vertical-align: middle;
    font-size: 20px;
    line-height: 140%;
    margin-bottom: 16px;
    text-align: left;
  }
  &.selected {
    height: 100%;
    min-height: calc(100vh - 52px);
  }
  ul.section {
    li {
      width: 100%;
      display: inline-block;
      margin-bottom: 50px;
      h3 {
        padding: 10px 0;
        i {
          margin-left: 20px;
          cursor: pointer;
        }
      }
      &.half {
        width: calc(50% - 20px);
        margin-right: 10px;
      }
    }
  }
  .buttons {
    position: absolute;
    top: 40px;
    right: 50px;
    button {
      padding: 5.5px 10px !important;
      margin-right: 10px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .nav_box {
    position: relative;
  }
  section {
    padding: 20px;
    background-color: var(--white);
    border: 1px solid var(--grey4);
    text-align: center;
    border-radius: 4px;
    margin-bottom: 25px;
    h4 {
      display: block;
      text-align: left;
      font-weight: 400;
      font-size: 14px;
      margin-bottom: 12px;
    }
  }
  .section_wrap {
    width: 100%;
    .section_left {
      width: 70%;
      display: inline-block;
    }
    .memo {
      margin-left: 1%;
      width: 29%;
      display: inline-block;
      min-width: 0;
      vertical-align: top;
      font-size: 14px;
      height: 766px;
      position: relative;
      margin-top: 0;
      h4 {
        text-align: left;
        display: block;
        margin-bottom: 10px;
        color: var(--text);
        font-weight: 700;
        font-weight: 14px;
      }
      ul {
        scrollbar-width: none;
        -ms-overflow-style: none;
        overflow-y: auto;
        position: relative;
        height: 600px;
        &::-webkit-scrollbar {
          width: 10px;
        }
        &::-webkit-scrollbar-thumb {
          background-color: #7f7f7f;
          background-color: rgba(0, 0, 0, 0.3);
          opacity: 0.6;
          border-radius: 10px;
          background-clip: padding-box;
          border: 2px solid transparent;
        }
        &::-webkit-scrollbar-track {
          background-color: #f3f3f3;
          border-radius: 10px;
          box-shadow: inset 0px 0px 5px white;
        }
        li {
          display: block;
          background-color: var(--grey5);
          margin-bottom: 10px;
          text-align: left;
          padding: 14px;
          border-radius: 10px;
          width: 97%;
          h5 {
            line-height: 140%;
            font-size: 14px;
            font-weight: 400;
            margin-bottom: 10px;
            color: var(--text);
          }
          p {
            color: var(--grey2);
          }
        }
      }
      > div {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 20px;
        border-top: 1px solid var(--grey4);
        input[type="text"] {
          display: inline-block;
          vertical-align: middle;
          width: 80%;
          height: 34px;
          padding: 7px 12px;
          text-align: left;
          margin-right: 1%;
          border-radius: 4px;
        }
        button {
          margin-right: 0;
        }
      }
    }
  }


  // 커뮤니티

  .playground.ournews{
    margin-top:0 !important;


    .news{
      ul{
        @media screen and (max-width: 809px) {
          gap: 30px 24px;
        }
      }
    }




  }
  

  .community{
    .news{
      margin-top:40px;


      ul{
        margin-bottom:0 !important ;
      }

      .iconbox{
          position:relative !important;
          margin-left:7px;
      }

      .main-news{
        height:300px;
        background:#f0f0f0;
        position:relative;
        background-repeat:no-repeat;
        background-size:cover;
        background-position: center;
        border-radius:14px;
        padding:32px 28px;
        display: flex;
        align-items: end;

        margin-bottom:7px;

        @media screen and (max-width: 809px) {
              padding:26px 18px;
        }

        img{
          height:100%;
        }

        .title-box{
          position:absolute;

          h6{
            width: auto !important;
          }

          

          h4{
            font-size:24px;
            font-weight:600;
            color:white;
            margin-bottom:8px;
            height:auto;

            @media screen and (max-width: 809px) {
              font-size:18px;
                 
            }

          }

          .username{
            color:white;
          }

          .date{
            color:white;
          }

          .profile{
            border:none;
          }

       
          .tagbox{

            margin:10px 0;
              span{
                opacity:0.9;
                color:#333;
              }
            }


        }


      }


      ul{
         
          gap: 0px 24px !important;
          grid-template-columns: 1fr !important;

          li.sub-news{
            display:flex;
            height:auto !important;
            border-bottom:1px solid #e7e7e7;
            padding : 20px 26px;


          @media screen and (max-width: 1199px) and (min-width: 810px) {

          }

          @media screen and (max-width: 809px) {
            padding : 20px 5px;
            align-items: center;

          }

          


            &:hover{
              cursor:pointer;
              background:#fafafa;
            }


            h4{
              font-size:21px;
              height:auto;
              margin-bottom:18px;

              @media screen and (max-width: 1199px) and (min-width: 810px) {
                font-size:18px;
              }
              @media screen and (max-width: 809px) {
                font-size:14px;
                margin-right: 10px;
                 margin-bottom: 14px;
              }
                
            }
            
            .cont-box{
              width:100%;
              @media screen and (max-width: 809px) {
                width:70%;
              }
            }

            .news-image-box{
              width:240px;
              height:140px;
              border-radius:12px;
              background-repeat:no-repeat;
              background-size:cover;
              background-position: center;

              @media screen and (max-width: 1199px) and (min-width: 810px) {
                width:220px;
              }

              @media screen and (max-width: 809px) {
                width:30%;
                height:100px;
              }
            }

            .news-image{
              width:185px !important;
              height:135px;
            }



            .tagbox{
              margin: 10px 0 12px 0;
            }


          }
      
      }
    }
  
  }


  .playground.search{

    &>h2{
      margin-left:auto;
      margin-right:auto;
      width:1110px;


      @media screen and (max-width: 1199px) and (min-width: 810px) {
        width:746px;        
      }
      @media screen and (max-width: 809px) {
        width:90%;
      }
    }

    .news{
     
      ul{
            margin-bottom:80px;
      }
      &.playground{
        ul{
            margin-bottom:10px;
        }
      }
    } 
  }

  
  .news-view{
    

  .info{
    @media screen and (max-width: 809px) {
      margin-bottom:5px;
    }
  }

  .tab{

  white-space:nowrap;
    
      span{
        @media screen and (max-width: 809px) {
          margin: 22px 6px 22px 0;
          font-size:13px;
          padding:11px 14px;
        }
      }
  }

  


  
  
  }

  

  // 뉴스 놀이터
  .playground {
    margin-top: 45px;


    .playround_title {
      font-weight: 700;
      font-size: 30px;
      line-height: 140%;
      color: var(--text);
      background: #f0ebff;
      height: 280px;
      position: relative;
      > div {
        width: 1128px;
        height: 280px;
        position: relative;
        margin: 0 auto;
        img {
          &:nth-child(1) {
            width: 267px;
            height: 209px;
            position: absolute;
            left: 0%;
            bottom: 2%;
          }
          &:nth-child(2) {
            width: 219px;
            height: 150px;
            position: absolute;
            right: 8%;
            bottom: 0;
          }
          &:nth-child(3) {
            width: 90px;
            height: 82px;
            position: absolute;
            right: 0%;
            bottom: 40%;
          }
        }
        h2 {
          text-align: center;
          margin-bottom: 8px;
          padding-top: 90px;
          font-size: 30px;
          span {
            color: var(--main);
          }
        }
        p {
          width: 1112px;
          margin: 0 auto;
          font-size: 16px;
          line-height: 160%;
          text-align: center;
          letter-spacing: -0.08em;
          color: #646f7c;
          font-weight: 400;
        }
        @media screen and (max-width: 1199px) and (min-width: 810px) {
          width: 100%;
          h2 {
            width: 720px;
            margin: 0 auto;
            margin-bottom: 8px;
          }
          p {
            width: 720px;
          }
        }
        @media screen and (max-width: 809px) {
          width: 100%;
          h2 {
            width: 90%;
            text-align: center;
            font-size: 26px;
            margin: 0 auto;
            margin-bottom: 8px;
          }
          p {
            width: 60%;
            text-align: center;
            font-size: 15px;
            word-break: keep-all;
          }
          img {
            &:nth-child(1) {
              width: 144px;
              height: 122px;
              position: absolute;
              left: -10%;
              top: 0;
            }
            &:nth-child(2) {
              width: 117px;
              height: 105px;
              position: absolute;
              right: 0%;
              bottom: 0;
            }
            &:nth-child(3) {
              width: 48px;
              height: 43px;
              position: absolute;
              right: 0%;
              bottom: 42%;
            }
          }
        }
      }
    }
    .tab {
      width: 1112px;
      margin: 0 auto;
      @media screen and (max-width: 1199px) and (min-width: 810px) {
        width: 720px;
      }
      @media screen and (max-width: 809px) {
        width: 90%;
        white-space: nowrap;
      }
      span {
        display: inline-block;
        background: #f2f4f6;
        border-radius: 6px;
        padding: 11px 16px;
        font-weight: 500;
        font-size: 14px;
        line-height: 140%;
        letter-spacing: -0.08em;
        color: #6a6a6a;
        margin: 50px 0;
        margin-right: 8px;
        cursor: pointer;
        &.on {
          background-color: var(--main);
          color: var(--white);
        }

        @media screen and (max-width: 809px) {
          font-size:13px;
          margin: 25px 8px 25px 0;
        }

      }
    }
    .news {

    &:last-child{

      ul{
        margin-bottom: 100px;
      }

    }

      h2 {
        font-size: 30px;
        line-height: 140%;
        letter-spacing: -0.08em;
        color: var(--text);
        font-weight: 700;
        line-height: 140%;
        width: 1112px;
        margin: 0 auto;
        margin-bottom: 34px;
        letter-spacing: -0.08em;
        position: relative;
        > span {
          color: var(--main);
        }
        &.news1_text {
          font-size: 30px;
          line-height: 140%;

          color: var(--text);
          font-weight: 700;
          line-height: 140%;
          width: 1112px;
          margin: 0 auto;
          margin-bottom: 34px;

          position: relative;
          &::before {
            content: "";
            position: absolute;
            width: 930px;
            height: 1px;
            display: block;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            z-index: 9;
            background-color: var(--grey3);
            @media screen and (max-width: 1199px) and (min-width: 810px) {
              width: 540px;
            }
            @media screen and (max-width: 809px) and (min-width: 701px) {
              width: 507px !important;
            }
            @media screen and (max-width: 700px) {
              width: calc(100% - 175px);
            }
          }
        }
        &::before {
          content: "";
          position: absolute;
          width: 895px;
          height: 1px;
          display: block;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          z-index: 9;
          background-color: var(--grey3);
          @media screen and (max-width: 1199px) and (min-width: 810px) {
            width: 507px;
          }
          @media screen and (max-width: 809px) and (min-width: 701px) {
            width: 472px !important;
          }
          @media screen and (max-width: 700px) {
            width: calc(100% - 180px);
          }
        }
        @media screen and (max-width: 1199px) and (min-width: 810px) {
          width: 720px !important;
        }
        @media screen and (max-width: 809px) and (min-width: 701px) {
          width: 90% !important;
        }
        @media screen and (max-width: 700px) {
          width: 90% !important;
          font-size: 25px !important;
        }
      }
      ul {
        width: 1112px;
        margin: 0 auto;
        display: grid;
        flex-direction: column;
        gap: 50px 24px;
        grid-template-columns: repeat(auto-fill, calc(25% - 18px));
        position: relative;
        margin-bottom: 60px;


        @media screen and (max-width: 809px) {
          gap: 12px 24px;
          margin-bottom: 20px;

        }
        li {
          height: 317px;
          &.news_none {
            height: 20px;
          }
          .no_image {
            width: 100%;
            height: 180px;
            display: block;
            border-radius: 14px;
            background-color: var(--grey1);
            cursor: pointer;
          }
          > img {
            width: 100%;
            height: 180px;
            display: block;
            object-fit: cover;
            border-radius: 14px;
            background-color: var(--grey1);
            cursor: pointer;
          }


          .username-box{

          width:100%;
          position:relative;
          display: flex;
          align-items:center;

          .profile{
              width: 29px;
              height: 29px;
              border: 1px solid #ccc;
              margin-right:7px;
              border-radius: 50%;
              top: 1px;
              position: relative;
            }
          .username{
            font-weight:bold;
            margin-right:8px;
            color:#808080;
            cursor:pointer;

            

            &:hover{
              color: #aaa;
            }
          }
            
          }

          .tagbox {
            margin: 12px 0;

            @media screen and (max-width: 809px) {
              margin: 12px 0 10px 0;
            }

            span {
              padding: 6px 10px;
              background: #f2f4f6;
              border-radius: 4px;
              margin-right: 6px;
              display: inline-block;
              font-weight: 500;
              font-size: 12px;
              line-height: 140%;
              color: #555;
            }
          }
          h4 {
            font-weight: 500;
            font-size: 18px;
            line-height: 140%;
            margin-bottom: 10px;
            overflow: hidden;
            white-space: normal;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            word-break: keep-all;
            height: 50px;

            @media screen and (max-width: 809px) and (min-width: 701px) {
              font-size: 16px !important; 
            }

            @media screen and (max-width: 809px) {
              height: auto;
              margin-bottom: 12px;
            }




          }
          h6 {

          width:100%;
          position:relative;

            font-weight: 400;
            font-size: 15px;
            line-height: 160%;
            color: #a2a7b1;
          }
          .iconbox {
            display: inline-block;
            vertical-align: middle;
            float: right;

            position: absolute;
            right: 0;
            margin-top:2px;

            span {
              display: inline-block;

              img {
                cursor: pointer;
                border-radius: 0;
                &:nth-child(1) {
                  width: 21px;
                  height: 18px;
                }
                &:nth-child(2) {
                  width: 21px;
                  height: 18px;
                }
              }
            }
          }
        }
        @media screen and (max-width: 1199px) and (min-width: 810px) {
          width: 730px;
          grid-template-columns: repeat(auto-fill, calc(33% - 14px));
          li {
          }
        }
        @media screen and (max-width: 809px) {
          width: 90%;
          grid-template-columns: repeat(auto-fill, calc(100%));
          
          
          li {
            h4 {
              font-size: 16px;
              line-height: 150%;
            }
          }
        }
      }
    }

    // 뉴스놀이터 검색 결과 화면
    .search_open {
      width: 100%;
      height: 96px;
      background-color: #fff;
      > div {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        width: 1112px;
        height: 52px;
        z-index: 3;
        background-color: transparent;
        border-radius: 6px;
        box-sizing: border-box;
        @media screen and (max-width: 1199px) and (min-width: 810px) {
          width: 746px;
        }
        @media screen and (max-width: 809px) {
          width: 90%;
        }
        input[type="text"] {
          width: 100%;
          background-color: var(--grey1);
          border: 1px solid var(--grey3);
          height: 52px;
          border-radius: 10px;
          padding: 14px 20px;
          box-sizing: border-box;
          position: relative;
          &::placeholder {
            font-size: 16px;
            line-height: 140%;
            color: var(--grey5);
            letter-spacing: -0.08em;
          }
          &:focus {
            border: 1px solid var(--main);
          }
        }
        .xi-search {
          font-size: 20px;
          position: absolute;
          top: 16px;
          left: 20px;
          color: var(--grey4);
        }
        img {
          width: 24px;
          height: 24px;
          position: absolute;
          right: 20px;
          top: 15px;
        }
      }
    }
    .search_done {

      gap:24px 24px !important;

      display: block;
      @media screen and (max-width: 809px) {
        width: 90%;
      }
      li {
        margin-bottom: 72px;
        height: auto;

        img {
          width: 280px;
          height: 192px;
          display: inline-block;
          margin-right: 48px;
          vertical-align: top;
          @media screen and (max-width: 809px) {
            width: 100%;
            margin-right: 0;
          }
        }
        .no_image {
          width: 280px;
          height: 192px;
          display: inline-block;
          margin-right: 48px;
          vertical-align: top;
          background-color: var(--grey3);
          border-radius: 14px;
          @media screen and (max-width: 809px) {
            width: 100%;
            margin-right: 0;
          }
        }
        > div {
          display: inline-block;
          vertical-align: middle;
          
          // width: calc(100% - 328px);


          @media screen and (max-width: 809px) {
            width: 100%;
            display: block;
          }
        }
        h4 {
          font-weight: 700;
          font-size: 17px !important;
          line-height: 140%;
          height: auto !important;
          @media screen and (max-width: 809px) {
            font-size: 18px;
          }
          strong {
            color: var(--main);
          }
        }
        p {
          font-weight: 400;
          font-size: 15px;
          line-height: 170%;
          color: #646f7c;
          max-height: 54px;
          overflow: hidden;
          white-space: normal;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          margin-bottom: 16px;
          @media screen and (max-width: 809px) {
            font-size: 15px;
          }
        }
      }
    }
  }

  // 뉴스놀이터 상세
  .news_add {
    h2 {
      width: 1112px;
      text-align: left;
      padding-top: 140px;
      font-weight: 700;
      font-size: 26px;
      line-height: 140%;
      margin: 0 auto;
      margin-bottom: 8px;
      @media screen and (max-width: 1199px) and (min-width: 810px) {
        width: 730px;
      }
      @media screen and (max-width: 809px) {
        width: 90%;
      }
    }
    p {
      width: 1112px;
      font-weight: 400;
      font-size: 18px;
      line-height: 140%;
      color: #28323c;
      margin: 0 auto;
      text-align: left;
      margin-bottom: 30px;
      @media screen and (max-width: 1199px) and (min-width: 810px) {
        width: 730px;
      }
      @media screen and (max-width: 809px) {
        width: 90%;
      }
    }
    .news_none {
      width: 1112px;
      margin: 0 auto;
      margin-bottom: 200px;
      @media screen and (max-width: 1199px) and (min-width: 810px) {
        width: 730px;
      }
      @media screen and (max-width: 809px) {
        width: 90%;
      }
    }
    .login_news {
      width: 1112px;
      margin: 0 auto;
      font-size: 16px;
      margin-bottom: 100px;
      span {
        cursor: pointer;
        color: var(--main);
        border-bottom: 1px solid var(--main);
      }

      @media screen and (max-width: 1199px) and (min-width: 810px) {
        width: 730px;
      }
      @media screen and (max-width: 809px) {
        width: 90%;
        margin-bottom: 80px;
      }
    }

    // 관련뉴스
    .swiper-container {
      @media screen and (max-width: 809px) {
        width: 90% !important;
      }
      .swiper-wrapper {
        width: 1112px;
        margin: 0 auto;
        display: grid;
        flex-direction: column;
        gap: 40px 24px;
        grid-template-columns: repeat(auto-fill, calc(25% - 28px));
        position: relative;
        margin-bottom: 80px;
        @media screen and (max-width: 1199px) and (min-width: 810px) {
          width: 730px;
          grid-template-columns: repeat(auto-fill, calc(33% - 14px));
        }
        @media screen and (max-width: 809px) {
          grid-template-columns: repeat(auto-fill, calc(50% - 14px));
        }

        .swiper-slide {
          height: 317px !important;
          width: 100% !important;
          @media screen and (max-width: 809px) {
            h4 {
              font-size: 16px;
              line-height: 150%;
            }
          }
          > img {
            width: 100%;
            height: 180px;
            display: block;
            object-fit: cover;
            border-radius: 14px;
            background-color: var(--grey1);
            cursor: pointer;
          }
          > .tagbox {
            margin: 12px 0;
            span {
              padding: 6px 10px;
              background: #f2f4f6;
              border-radius: 4px;
              margin-right: 6px;
              display: inline-block;
              font-weight: 500;
              font-size: 12px;
              line-height: 140%;
              color: #646f7c;
            }
          }
          h4 {
            font-weight: 500;
            font-size: 18px;
            line-height: 140%;
            margin-bottom: 10px;
            overflow: hidden;
            white-space: normal;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            word-break: keep-all;
            height: 50px;
          }
          h6 {
            font-weight: 400;
            font-size: 15px;
            line-height: 160%;
            color: #a2a7b1;
            display: inline-block;
            vertical-align: middle;
          }
          .iconbox {
            display: inline-block;
            vertical-align: middle;
            float: right;
            span {
              display: inline-block;
              &:nth-child(1) {
                margin-right: 8px;
              }
              img {
                cursor: pointer;
                border-radius: 0;
                &:nth-child(1) {
                  width: 21px;
                  height: 18px;
                }
                &:nth-child(2) {
                  width: 21px;
                  height: 18px;
                }
              }
            }
          }
        }
      }
    }

    .comment_playground {
      width: 1112px;
      margin: 0 auto;
      margin-bottom: 100px;
      @media screen and (max-width: 1199px) and (min-width: 810px) {
        width: 730px;
      }
      @media screen and (max-width: 809px) {
        width: 90%;
      }
      h4 {
        font-weight: 500;
        font-size: 18px;
        line-height: 140%;
        color: #374553;
        span {
          font-weight: 500;
          font-size: 18px;
          line-height: 140%;
          color: #6c38ff;
          margin-left: 4px;
        }
      }
      > div {
        &:nth-child(2) {
          background: #f2f4f6;
          border-radius: 6px;
          padding: 14px 16px;
          margin-top: 16px;
          margin-bottom: 20px;
          img {
            width: 24px;
            height: 24px;
            display: inline-block;
            vertical-align: top;
            margin-right: 10px;
          }
          p {
            display: inline-block;
            font-weight: 500;
            font-size: 16px;
            line-height: 150%;
            width: calc(100% - 34px);
            vertical-align: middle;
            margin-bottom: 0;
            color: var(--grey6);
            word-break: break-all;
          }
        }
        &:nth-child(3) {
          border-radius: 6px;
          margin-bottom: 10px;
          img {
            width: 40px;
            height: 40px;
            display: inline-block;
            vertical-align: top;
            margin-right: 10px;
            border-radius: 100%;
            object-fit: cover;
          }
          textarea {
            width: 95.5%;
            min-height: 55px;
            max-height: 110px;
            border: 1px solid var(--grey4);
            border-radius: 4px;
            font-size: 16px;
            padding: 14px 16px;
            &::-webkit-scrollbar {
              width: 10px;
            }
            &::-webkit-scrollbar-thumb {
              background-color: #7f7f7f;
              background-color: rgba(0, 0, 0, 0.3);
              opacity: 0.6;
              border-radius: 10px;
              background-clip: padding-box;
              border: 2px solid transparent;
            }
            &::-webkit-scrollbar-track {
              background-color: #f3f3f3;
              border-radius: 10px;
              box-shadow: inset 0px 0px 5px white;
            }
            &::placeholder {
              color: #a2a7b1;
            }
            &:focus {
              border: 1px solid var(--main);
            }
            @media screen and (max-width: 1199px) and (min-width: 810px) {
              width: 92.5%;
            }
            @media screen and (max-width: 809px) {
              width: calc(100% - 50px);
            }
          }
          div {
            text-align: right;
            button {
              background: #a788ff;
              color: var(--white);
              border-radius: 6px;
              padding: 11px 16px;
              margin-top: 10px;
              font-size: 14px;
              line-height: 140%;
              &:hover {
                background-color: var(--main);
              }
            }
          }
        }
      }
      ul {
        border-radius: 6px;
        li {
          padding: 30px 0;
          border-bottom: 1px solid #e9ebee;
          img {
            width: 40px;
            height: 40px;
            display: inline-block;
            vertical-align: top;
            margin-right: 10px;
            border-radius: 100%;
            object-fit: cover;
          }
          div {
            display: inline-block;
            width: 95.5%;
            @media screen and (max-width: 1199px) and (min-width: 810px) {
              width: 92.5%;
            }
            @media screen and (max-width: 809px) {
              width: calc(100% - 50px);
            }
            h5 {
              display: inline-block;
              vertical-align: middle;
              font-weight: 700;
              font-size: 14px;
              line-height: 140%;
              color: #28323c;
              margin-right: 4px;
            }
            span {
              display: inline-block;
              vertical-align: middle;
              background: #f2f4f6;
              border-radius: 4px;
              font-weight: 600;
              font-size: 12px;
              line-height: 140%;
              color: #646f7c;
              padding: 4px 6px;
              margin-right: 7px;
            }
            h6 {
              display: inline-block;
              vertical-align: middle;
              font-weight: 400;
              font-size: 12px;
              line-height: 140%;
              color: #a2a7b1;
            }
          }
          p {
            font-weight: 400;
            font-size: 16px;
            line-height: 170%;
            width: 100%;
            margin-bottom: 0;
          }
        }
      }
    }
  }

  // select box
  .css-1u9uq39-container {
    margin-bottom: 0;
    position: relative !important;
    vertical-align: middle;
    span {
      background-color: transparent;
      padding: 0;
      border-radius: 0;
    }
    div {
      margin-bottom: 0;
    }
  }

  .btns {
    button {
      width: auto !important;
    }
  }
`;

export const PaymentSt = styled.div`



  // 결제하기
  .payment {
    @media screen and (max-width: 809px) {
      width: 92%;
    }
    width: 512px;
    margin: 0 auto;
    margin-top: 100px;
    margin-bottom: 200px;
    > img {
      width: 100px;
      height: 90px;
      display: block;
      margin: 0 auto;
    }
    > .agree {
      ul {
        li {
          cursor: pointer;
          &:last-child {
            padding-bottom: 20px;
            border-bottom: 1px solid #e9ebee;
            margin-bottom: 20px;
          }
          > p {
            font-weight: 400;
            font-size: 12px;
            line-height: 140%;
            color: #a2a7b1;
            display: inline-block;
            vertical-align: middle;
          }
          > img {
            display: inline-block;
            vertical-align: middle;
            width: 16px;
            height: 16px;
            float: right;
          }
        }
      }
      p {
        color: #a2a7b1;
        text-align: center;
        font-size: 12px;
        margin-bottom: 14px;
      }
      button {
        width: 100%;
        height: 58px;
        line-height: 58px;
        background-color: var(--main);
        color: var(--white);
        font-weight: 700;
        font-size: 16px;
        line-height: 140%;
        text-align: center;
        color: #ffffff;
        border-radius: 8px;
        display: block;
      }
    }
    // 결제 완료
    .payment_done {
      background: #f7f8f9;
      border-radius: 10px;
      padding: 20px;
      margin-bottom: 14px;
      li {
        margin-bottom: 20px;
        &:last-child {
          margin-bottom: 0;
        }
        h3 {
          font-weight: 700;
          font-size: 18px;
          line-height: 140%;
        }
        h4 {
          font-weight: 700;
          font-size: 14px;
          line-height: 140%;
          color: #646f7c;
          margin-bottom: 10px;
        }
        .info {
          p {
            font-weight: 400;
            font-size: 14px;
            line-height: 170%;
            color: #646f7c;
            span {
              margin-right: 15px;
            }
          }
        }
        > div {
          h5 {
            display: inline-block;
            vertical-align: middle;
            font-weight: 400;
            font-size: 14px;
            line-height: 140%;
          }
          > div {
            display: inline-block;
            vertical-align: middle;
            float: right;
            p {
              font-weight: 700;
              font-size: 14px;
              line-height: 140%;
            }
          }
        }
      }
    }
    > button {
      width: 100%;
      height: 58px;
      line-height: 58px;
      background-color: var(--main);
      color: var(--white);
      font-weight: 700;
      font-size: 16px;
      line-height: 140%;
      text-align: center;
      color: #ffffff;
      border-radius: 8px;
      display: block;
      margin-bottom: 200px;
    }
  }
`;
export const PaymentContainer = styled.div`
  // 결제하기
  height: ${({ open }) => (open ? "100%" : "61px")};
  position: relative;
  overflow: hidden;
  padding: 20px;
  margin-bottom: 14px;
  background-color: var(--grey1);
  border-radius: 10px;
  cursor: pointer;
  box-sizing: border-box;
  .payment_box {
    width: 100%;
    > div {
      position: relative;
      h3 {
        font-weight: 700;
        font-size: 18px;
        line-height: 140%;
        letter-spacing: -0.04em;
        display: inline-block;
        vertical-align: middle;
        margin-bottom: 0;
      }
      .pay_right {
        display: inline-block;
        vertical-align: middle;
        float: right;
        h4 {
          display: inline-block;
          vertical-align: middle;
          color: var(--main);
          font-weight: 700;
          font-size: 18px;
          line-height: 140%;
          span {
            font-size: 14px;
          }
        }
        img {
          width: 16px;
          height: 16px;
          transform: rotate(90deg);
          display: inline-block;
          vertical-align: middle;
          margin-left: 10px;
          transform: ${({ open }) =>
            open ? "rotate(90deg)" : " rotate(0deg)"};
        }
      }
    }

    > .month_subscribe {
      cursor: initial;
      ul {
        margin-top: 20px;
        li {
          margin-bottom: 14px;
          font-size: 14px;
          line-height: 140%;
          color: #374553;
          img {
            width: 20px;
            height: 20px;
            display: inline-block;
            vertical-align: middle;
            margin-right: 10px;
          }
          p {
            display: inline-block;
            vertical-align: middle;
          }
        }
      }
    }
    > .payment_amount {
      cursor: initial;
      ul {
        margin-top: 20px;
        li {
          margin-bottom: 20px;
          font-size: 14px;
          line-height: 140%;
          color: #374553;
          &:nth-child(1) {
            padding-bottom: 20px;
            border-bottom: 1px solid #e9ebee;
          }
          h5 {
            display: inline-block;
            vertical-align: middle;
          }
          p {
            display: inline-block;
            vertical-align: middle;
            float: right;
          }
        }
      }
    }
    > .payment_methods {
      cursor: initial;
      ul {
        margin-top: 20px;
        li {
          margin-bottom: 20px;
          font-size: 14px;
          line-height: 140%;
          color: #374553;
          h5 {
            display: inline-block;
            vertical-align: middle;
          }
          > button {
            float: right;
            padding: 10px 14px;
            display: inline-block;
            vertical-align: middle;
            background: #ffffff;
            border: 1px solid #6c38ff;
            border-radius: 6px;
            color: var(--main);
            font-weight: 500;
            font-size: 14px;
            line-height: 140%;
          }
        }
      }
    }
    > .child_list {
      cursor: initial;
      ul {
        margin-top: 20px;
        li {
          border: 1px solid #e9ebee;
          margin-bottom: 10px;
          border-radius: 12px;
          padding: 12px 20px;
          cursor: pointer;
          &:hover {
            background: #f2f4f6;
          }
          &.on {
            border: 1px solid var(--main);
          }
          > img {
            width: 48px;
            height: 48px;
            display: inline-block;
            vertical-align: middle;
            margin-right: 12px;
            border-radius: 100%;
          }
          p {
            font-weight: 500;
            font-size: 16px;
            line-height: 140%;
            color: #28323c;
            display: inline-block;
            vertical-align: middle;
          }
          > div {
            display: inline-block;
            vertical-align: middle;
            float: right;
            img {
              width: 20px;
              height: 20px;
              margin-top: 13px;
            }
          }
        }
      }
    }
  }
`;

// Landing
export const Landing = styled.div`
  width: 100%;
  overflow: hidden;

  h1 {
    margin-bottom: 8px;
  }
  > div {
    &:nth-child(1) {
      color: var(--white);
      position: relative;
      text-align: center;
      height: 100vh;
      padding-top: 120px;
      background: linear-gradient(
        180deg,
        #6c38ff 0%,
        rgba(108, 56, 255, 0.7) 100%
      );
      > .title {
        width: 100%;
        margin: 0 auto;
        position: absolute;
        top: 15%;
        left: 50%;
        -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        transform: translateX(-50%);
        @media screen and (max-width: 809px) {
          top: 12%;
        }
        z-index: 9;
        h1 {
          font-size: 40px;
          line-height: 150%;
          font-weight: 700;
          margin-bottom: 8px;
          text-align: center;

          br {
            display: none;
          }
          @media screen and (max-width: 809px) {
            font-size: 24px;
            br {
              display: block;
            }
            margin-bottom: 16px;
          }
        }
        p {
          font-weight: 300;
          font-size: 24px;
          line-height: 150%;
          text-align: center;
          @media screen and (max-width: 809px) {
            font-size: 16px;
          }
        }
        > img {
          &:nth-child(1) {
            width: 107px;
            height: 97px;
            margin: 0 auto;
            display: block;
            margin-bottom: 20px;

            @media screen and (max-width: 809px) {
              width: 60px;
              height: 55px;
              margin-bottom: 16px;
            }
          }
        }
      }
      .laptop {
        width: 70vh;
        position: absolute;
        bottom: 0%;
        left: 50%;
        transform: translateX(-50%);
        img {
          width: 100%;
        }
        @media screen and (max-width: 809px) {
          width: 70vh;
          -webkit-transform: translateX(-11%);
          -ms-transform: translateX(-11%);
          transform: translateX(-46%);
          left: 50%;
        }
      }
      .main_list {
        img {
          display: block;
          &:nth-child(1) {
            width: 12%;
            position: absolute;
            left: 18%;
            bottom: 10%;
            @media screen and (max-width: 1600px) and (min-width: 1368px) {
              width: 10%;
            }
            @media screen and (max-width: 1367px) and (min-width: 1024px) {
              width: 12%;
              position: absolute;
              left: 13%;
              bottom: 6%;
            }
            @media screen and (max-width: 1023px) and (min-width: 810px) {
              width: 15%;
              position: absolute;
              left: 8%;
              bottom: 10%;
            }
            @media screen and (max-width: 809px) {
              display: none;
            }
          }
          &:nth-child(2) {
            width: 1%;
            position: absolute;
            left: 35%;
            bottom: 40%;
            @media screen and (max-width: 1600px) and (min-width: 1368px) {
              bottom: 28%;
            }
            @media screen and (max-width: 1367px) and (min-width: 1024px) {
              width: 1.5%;
              position: absolute;
              left: 28%;
              bottom: 29%;
            }
            @media screen and (max-width: 1023px) and (min-width: 810px) {
              width: 1.5%;
              position: absolute;
              left: 27%;
              bottom: 34%;
            }
            @media screen and (max-width: 809px) {
              width: 3%;
              position: absolute;
              left: 11%;
              bottom: 70%;
            }
          }
          &:nth-child(3) {
            width: 1%;
            position: absolute;
            right: 31%;
            bottom: 35%;
            @media screen and (max-width: 1600px) and (min-width: 1118px) {
              width: 2%;
              position: absolute;
              right: 30%;
              bottom: 30%;
            }
            @media screen and (max-width: 1199px) and (min-width: 810px) {
              width: 2%;
              position: absolute;
              right: 18%;
              bottom: 17%;
            }
            @media screen and (max-width: 809px) {
              width: 6%;
              position: absolute;
              right: 87%;
              bottom: 78%;
            }
          }
          &:nth-child(4) {
            width: 9%;
            position: absolute;
            left: 25%;
            bottom: 47%;
            @media screen and (max-width: 1600px) and (min-width: 1368px) {
              width: 6%;
              position: absolute;
              left: 30%;
              bottom: 40%;
            }
            @media screen and (max-width: 1367px) and (min-width: 1024px) {
              width: 9%;
              position: absolute;
              left: 20%;
              bottom: 37%;
            }
            @media screen and (max-width: 1023px) and (min-width: 810px) {
              width: 9%;
              position: absolute;
              left: 20%;
              bottom: 40%;
            }
            @media screen and (max-width: 809px) {
              display: none;
            }
          }
          &:nth-child(5) {
            width: 6%;
            position: absolute;
            right: 30%;
            bottom: 50%;
            @media screen and (max-width: 1600px) and (min-width: 1368px) {
              width: 6%;
              position: absolute;
              right: 32%;
              bottom: 37%;
            }
            @media screen and (max-width: 1367px) and (min-width: 1024px) {
              width: 8%;
              position: absolute;
              right: 24%;
              bottom: 37%;
            }
            @media screen and (max-width: 1023px) and (min-width: 810px) {
              width: 9%;
              position: absolute;
              right: 20%;
              bottom: 32%;
            }
            @media screen and (max-width: 809px) {
              display: none;
            }
          }
          &:nth-child(6) {
            width: 7%;
            position: absolute;
            left: 22%;
            bottom: 38%;
            @media screen and (max-width: 1600px) and (min-width: 1368px) {
              width: 5%;
            }
            @media screen and (max-width: 1367px) and (min-width: 1024px) {
              width: 6%;
              position: absolute;
              left: 12%;
              bottom: 32%;
            }
            @media screen and (max-width: 1023px) and (min-width: 810px) {
              width: 7%;
              position: absolute;
              left: 11%;
              bottom: 39%;
            }
            @media screen and (max-width: 809px) {
              width: 34%;
              position: absolute;
              left: 78%;
              bottom: 69%;
            }
          }
          &:nth-child(7) {
            width: 9%;
            position: absolute;
            right: 22%;
            bottom: 10%;
            @media screen and (max-width: 1600px) and (min-width: 1368px) {
              width: 8%;
              position: absolute;
              right: 23%;
              bottom: 10%;
            }
            @media screen and (max-width: 1367px) and (min-width: 1024px) {
              width: 9%;
              position: absolute;
              right: 18%;
              bottom: 10%;
            }
            @media screen and (max-width: 1023px) and (min-width: 810px) {
              width: 12%;
              position: absolute;
              right: 17%;
              bottom: 3%;
            }
            @media screen and (max-width: 809px) {
              display: none;
            }
          }
          &:nth-child(8) {
            width: 40%;
            position: absolute;
            left: 50%;
            bottom: 0%;
            transform: translateX(-45%);
            @media screen and (max-width: 1367px) and (min-width: 1024px) {
              width: 50%;
            }
            @media screen and (max-width: 809px) {
              width: 90%;
            }
            @media screen and (max-width: 500px) {
              width: 130%;
            }
          }
          &:nth-child(9) {
            width: 8%;
            position: absolute;
            right: 23%;
            bottom: 40%;
            @media screen and (max-width: 1600px) and (min-width: 1368px) {
              width: 6%;
              position: absolute;
              right: 24%;
              bottom: 34%;
            }
            @media screen and (max-width: 1367px) and (min-width: 1024px) {
              width: 7%;
              position: absolute;
              right: 15%;
              bottom: 30%;
            }
            @media screen and (max-width: 1023px) and (min-width: 810px) {
              width: 8%;
              position: absolute;
              right: 9%;
              bottom: 23%;
            }
            @media screen and (max-width: 809px) {
              display: none;
            }
          }
        }
      }
      span {
        width: 100%;
        height: 153px;
        position: absolute;
        bottom: 0;
        left: 0;
        background: linear-gradient(
          180deg,
          rgba(108, 56, 255, 0) 0%,
          #6c38ff 100%
        );
      }
    }
    /* div2 */
    &:nth-child(2) {
      background-image: url(${Childrenimg});
      background-size: cover;
      background-repeat: no-repeat;
      height: 573px;
      width: 100%;
      background-position: center;
      @media screen and (max-width: 809px) {
        background-position-x: 34%;
      }
      > div {
        width: 998px;
        margin: 0 auto;
        padding-top: 174px;
        @media screen and (max-width: 1199px) and (min-width: 810px) {
          width: 380px;
          padding-top: 110px;
        }
        @media screen and (max-width: 809px) {
          width: 289px;
          text-align: center;
          padding-top: 128px;
        }
        h2 {
          display: inline-block;
          vertical-align: middle;
          font-weight: 700;
          font-size: 36px;
          line-height: 150%;
          text-align: center;
          font-weight: 700;
          color: #28323c;
          @media screen and (max-width: 1199px) and (min-width: 810px) {
            text-align: center;
            width: 100%;
          }
          @media screen and (max-width: 809px) {
            font-size: 24px;
          }
        }
        > div {
          position: relative;
          width: 349px;
          height: 110px;
          overflow: hidden;
          margin: 0 auto;
          margin: 0 20px;
          display: inline-block;
          vertical-align: middle;
          background: rgba(255, 255, 255, 0.8);
          border-radius: 30px;
          @media screen and (max-width: 1199px) and (min-width: 810px) {
            margin: 20px 0;
          }
          @media screen and (max-width: 809px) {
            font-size: 20px;
            display: block;
            margin: 20px 0;
            width: 287px;
          }
          > ul {
            width: 349px;
            overflow: hidden;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            height: 110px;
            li {
              display: block;
              font-size: 50px;
              line-height: 150%;
              text-align: center;
              color: var(--main);
              font-weight: 700;
              height: 110px;
              line-height: 110px;
              &:first-child {
                animation: slide 10s infinite;
                animation-delay: 0.6s;
              }
            }
            @media screen and (max-width: 809px) {
              width: 287px;
              li {
                font-size: 40px;
              }
            }
          }
        }
      }
      button {
        background-color: var(--main);
        padding: 14px 30px;
        border-radius: 20px;
        color: var(--white);
        font-weight: 700;
        font-size: 26px;
        line-height: 150%;
        text-align: center;
        margin: 0 auto;
        display: block;
        margin-top: 105px;
        cursor: pointer;
        @media screen and (max-width: 1199px) and (min-width: 810px) {
          margin-top: 67px;
        }
        @media screen and (max-width: 809px) {
          margin-top: 85px;
          width: 227px;
          font-size: 22px;
          letter-spacing: -0.06em;
        }
      }

      @keyframes slide {
        0% {
          margin-top: 0;
        }
        14% {
          margin-top: -110px;
        }
        28% {
          margin-top: -220px;
        }
        42% {
          margin-top: -330px;
        }
        56% {
          margin-top: -440px;
        }
        70% {
          margin-top: -550px;
        }
        85% {
          margin-top: -660px;
        }
        100% {
          margin-top: -770px;
        }
      }
    }
    /* div3 */
    &:nth-child(3) {
      padding-top: 140px;
      padding-bottom: 220px;
      text-align: center;
      h4 {
        font-weight: 700;
        font-size: 24px;
        text-align: center;
        line-height: 150%;
        color: var(--main);
        margin-bottom: 12px;
        letter-spacing: -0.08em;
        @media screen and (max-width: 809px) {
          font-size: 20px;
        }
      }
      h3 {
        font-weight: 700;
        font-size: 40px;
        line-height: 150%;
        text-align: center;
        color: var(--text);
        margin-bottom: 24px;
        letter-spacing: -0.08em;
        @media screen and (max-width: 809px) {
          font-size: 30px;
        }
      }
      p {
        font-weight: 400;
        font-size: 20px;
        line-height: 160%;
        text-align: center;
        color: var(--grey6);
        margin-bottom: 70px;
        @media screen and (max-width: 809px) {
          font-size: 16px;
          width: 52%;
          word-break: keep-all;
          margin: 0 auto;
          margin-bottom: 77px;
        }
      }

      .swiper-wrapper {
        position: relative;
        .swiper-slide {
          /* min-width: 460px !important; */
          /* width: 25%; */
          height: 300px;
          min-height: 300px;
          text-align: center;
          font-size: 18px;
          overflow: visible;
          /* Center slide text vertically */
          display: -webkit-box;
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          -webkit-justify-content: center;
          justify-content: center;
          -webkit-box-align: center;
          -ms-flex-align: center;
          -webkit-align-items: center;
          align-items: center;
          .news {
            height: 300px;
            border-radius: 24px;
            cursor: pointer;
            width: 100%;
            img {
              width: 200px;
              height: 200px;
              margin-top: 30px;
              margin-bottom: 15px;
              &.news01_img {
                width: 240px;
              }
            }
            p {
              font-weight: 700;
              font-size: 20px;
              line-height: 150%;
              color: var(--text);
            }
            &.news01 {
              background: #f0ebff;
            }
            &.news02 {
              background: rgba(86, 197, 0, 0.2);
            }
            &.news03 {
              background: rgba(255, 80, 122, 0.2);
            }
            &.news04 {
              background: rgba(255, 193, 72, 0.2);
            }
          }
        }
      }
    }
    /* div4 */
    &:nth-child(4) {
      width: 1128px;
      margin: 0 auto;
      margin-bottom: 300px;
      @media screen and (max-width: 809px) {
        width: 90%;
        margin-bottom: 230px;
      }
      h4 {
        font-weight: 700;
        font-size: 24px;
        text-align: center;
        line-height: 150%;
        color: var(--main);
        margin-bottom: 12px;
        letter-spacing: -0.08em;
        @media screen and (max-width: 809px) {
          font-size: 20px;
        }
      }
      h3 {
        font-weight: 700;
        font-size: 40px;
        line-height: 150%;
        text-align: left;
        color: var(--text);
        margin-bottom: 58px;
        letter-spacing: -0.08em;
        @media screen and (max-width: 809px) {
          font-size: 30px;
        }
      }
      div {
        display: inline-block;
        vertical-align: top;
        width: 744px;
        height: 529px;
        background: #f2f4f6;
        border-radius: 16px;
        margin-right: 72px;
        filter: drop-shadow(0px 4px 16px rgba(40, 50, 60, 0.1));
        border-radius: 16px;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        @media screen and (max-width: 809px) {
          width: 100%;
          display: block;
          margin-bottom: 70px;
        }
        @media screen and (max-width: 500px) {
          width: 100%;
          height: 242px;
          display: block;
          margin-bottom: 70px;
        }
      }
      ul {
        display: inline-block;
        vertical-align: top;
        width: 312px;
        @media screen and (max-width: 809px) {
          width: 100%;
        }
        li {
          font-weight: 500;
          font-size: 24px;
          line-height: 150%;
          color: var(--grey7);
          margin-bottom: 40px;
          @media screen and (max-width: 809px) {
            font-size: 20px;
          }
          img {
            width: 40px;
            height: 40px;
            display: block;
            margin-bottom: 20px;
          }
        }
      }
      @media screen and (max-width: 1199px) and (min-width: 810px) {
        width: 744px;
        div {
          display: block;
        }
        ul {
          width: 100%;
          margin-top: 80px;
          li {
            width: 312px;
            display: inline-block;
          }
        }
      }
    }
    /* div5 */
    &:nth-child(5) {
      width: 1128px;
      margin: 0 auto;
      margin-bottom: 285px;
      @media screen and (max-width: 1199px) and (min-width: 810px) {
        margin-bottom: 120px;
      }
      @media screen and (max-width: 809px) {
        width: 90%;
        margin-bottom: 110px;
      }
      > div {
        &:nth-child(1) {
          margin-bottom: 285px;
          @media screen and (max-width: 1199px) and (min-width: 810px) {
            margin-bottom: 170px;
          }
          @media screen and (max-width: 809px) {
            margin-bottom: 95px;
          }
        }
        h3 {
          font-weight: 700;
          font-size: 40px;
          line-height: 150%;
          letter-spacing: -0.08em;
          color: var(--text);
          margin-bottom: 30px;
          @media screen and (max-width: 809px) {
            font-size: 30px;
          }
        }
        > img {
          display: inline-block;
          vertical-align: top;
          width: 400px;
          height: 400px;
          margin-right: 219px;
          @media screen and (max-width: 809px) {
            width: 100%;
            margin: 0 auto;
            display: block;
            height: auto;
          }
        }
        > div {
          display: inline-block;
          vertical-align: top;
          @media screen and (max-width: 809px) {
            margin-top: 75px;
            display: block;
          }
          ul {
            li {
              background: #f7f8f9;
              border-radius: 14px;
              color: var(--grey1);
              padding: 18px 24px;
              box-sizing: border-box;
              margin-bottom: 14px;
              padding: 24px;
              @media screen and (max-width: 809px) {
                padding: 18px;
              }
              img {
                width: 40px;
                height: 40px;
                display: inline-block;
                vertical-align: top;
                margin-right: 14px;
                @media screen and (max-width: 809px) {
                  width: 32px;
                  height: 32px;
                }
              }
              span {
                display: inline-block;
                vertical-align: top;
                width: calc(100% - 48px);
                h4 {
                  font-size: 24px;
                  line-height: 150%;
                  font-weight: 700;
                  color: var(--text);
                  margin-bottom: 6px;
                  @media screen and (max-width: 809px) {
                    font-size: 18px;
                  }
                }
                p {
                  font-weight: 400;
                  font-size: 18px;
                  line-height: 150%;
                  color: var(--grey6);
                  @media screen and (max-width: 809px) {
                    font-size: 14px;
                    letter-spacing: -0.07em;
                  }
                }
              }
            }
          }
        }
      }
      @media screen and (max-width: 1199px) and (min-width: 810px) {
        width: 469px;
        > div {
          > img {
            display: block;
            margin: 0 auto;
            margin-bottom: 75px;
          }
          > div {
            ul {
              li {
                span {
                  p {
                    letter-spacing: -0.08em;
                  }
                }
              }
            }
          }
        }
      }
    }
    /* div6 */
    &:nth-child(6) {
      background-color: var(--grey1);
      padding-top: 126px;
      padding-bottom: 100px;
      position: relative;
      background-image: url(${Talk});
      background-repeat: no-repeat;
      h4 {
        font-weight: 700;
        font-size: 24px;
        text-align: center;
        line-height: 150%;
        color: var(--grey6);
        display: block;
        margin-bottom: 12px;
        z-index: 15;
        @media screen and (max-width: 809px) {
          font-size: 20px;
        }
      }
      h3 {
        font-weight: 700;
        font-size: 40px;
        line-height: 150%;
        text-align: center;
        color: var(--text);
        margin-bottom: 100px;
        z-index: 15;
        @media screen and (max-width: 809px) {
          font-size: 30px;
        }
      }
      .main_talk {
        width: 100%;
        height: 300px;
        @media screen and (max-width: 809px) {
          height: 310px;
        }
        .swiper-button-prev {
          left: 34%;
          right: auto;
          background: var(--white);
          width: 45px;
          height: 45px;
          border: 1px solid var(--grey4);
          border-radius: 100%;
          transform: translateY(-50%);
          @media screen and (max-width: 1024px) {
            left: 27%;
          }
          @media screen and (max-width: 767px) {
            left: 2%;
          }
        }
        .swiper-button-prev:after {
          color: var(--grey6);
          opacity: 0.7;
          font-size: 19px;
          font-weight: 700;
        }
        .swiper-button-next {
          right: 34%;
          background: var(--white);
          width: 45px;
          height: 45px;
          border: 1px solid var(--grey4);
          border-radius: 100%;
          transform: translateY(-50%);
          @media screen and (max-width: 1024px) {
            right: 27%;
          }
          @media screen and (max-width: 767px) {
            right: 2%;
          }
        }
        .swiper-button-next:after {
          color: var(--grey6);
          opacity: 0.7;
          font-size: 19px;
          font-weight: 700;
        }
        .swiper-slide {
          > div {
            cursor: pointer;
            height: 257px !important;
            box-sizing: border-box;
            padding: 40px;
            display: inline-block;
            background: var(--white);
            border: 1px solid #e9ebee;
            box-shadow: 0px 8px 24px rgba(109, 141, 173, 0.14);
            border-radius: 20px;
            color: var(--text);
            position: relative;
            @media screen and (max-width: 1024px) {
              padding: 35px;
            }
            &:last-child {
              margin-right: 0;
            }
            > p {
              font-size: 16px;
              line-height: 160%;
              width: 100%;
              white-space: initial;
              margin-bottom: 30px;
              @media screen and (max-width: 809px) {
                height: 150px;
                display: block;
              }
            }
            span {
              position: absolute;
              bottom: 40px;
              left: 40px;
              @media screen and (max-width: 1024px) {
                left: 35px;
              }
              img {
                width: 17px;
                height: 17px;
                display: inline-block;
                margin-right: 6px;
                &:last-child {
                  margin-right: 0;
                }
              }
            }
            .parents_text {
              position: absolute;
              bottom: 38px;
              right: 40px;
              width: fit-content;
              color: var(--grey5);
              margin-bottom: 0;
              @media screen and (max-width: 1200px) {
                font-size: 11px;
                margin-top: 4px;
                height: auto;
              }
              @media screen and (max-width: 1024px) {
                right: 35px;
              }
              @media screen and (max-width: 500px) {
                font-size: 13px;
                margin-top: 4px;
                height: auto;
                right: 35px;
              }
            }
          }
        }
      }
    }

    &.subscribe_main {
      width: 1128px;
      margin: 0 auto;
      margin-top: 120px;
      @media screen and (max-width: 1199px) and (min-width: 810px) {
        width: 758px;
      }
      @media screen and (max-width: 809px) {
        width: 90%;
      }
      > h4 {
        font-weight: 700;
        font-size: 30px;
        line-height: 140%;
        margin-bottom: 20px;
        @media screen and (max-width: 809px) {
          font-size: 18px;
        }
      }
      > ul {
        margin-bottom: 20px;
        &:nth-child(2) {
          li {
            display: inline-block;
            vertical-align: middle;
            margin-right: 20px;
            &:nth-child(1) {
              img {
                width: 267px;
                height: 209px;
                @media screen and (max-width: 1199px) and (min-width: 810px) {
                  width: 169px;
                  height: 169px;
                }
              }
              @media screen and (max-width: 809px) {
                width: 100%;
                display: block;
                img {
                  display: block;
                  margin: 0 auto;
                }
              }
            }
            &:nth-child(2),
            &:nth-child(3),
            &:nth-child(4) {
              width: 267px;
              height: 174px;
              background: var(--white);
              box-shadow: 0px 19px 54px rgba(195, 199, 209, 0.28);
              border-radius: 20px;
              padding: 20px 12px;
              box-sizing: border-box;
              font-weight: 700;
              line-height: 140%;
              text-align: center;
              position: relative;
              @media screen and (max-width: 1199px) and (min-width: 810px) {
                width: 174.5px;
                height: 174px;
              }
              @media screen and (max-width: 809px) {
                width: 100%;
                display: block;
                margin: 0 auto;
                margin-bottom: 26px;
              }
              h4 {
                font-size: 18px;
                color: #646f7c;
                display: block;
                margin-bottom: 18px;
                line-height: 140%;
              }
              h3 {
                font-size: 22px;
                margin-bottom: 18px;
                line-height: 140%;
              }
              button {
                width: 89px;
                height: 42px;
                background: #ffffff;
                border: 1px solid var(--main);
                border-radius: 6px;
                color: var(--main);
                font-weight: 500;
                font-size: 16px;
                line-height: 140%;
              }
              > img {
                width: 32px;
                height: 20px;
                display: block;
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                bottom: -17px;
              }
            }
            &:last-child {
              margin-right: 0;
              background-color: var(--main);
              h4 {
                color: var(--white);
              }
              h3 {
                color: var(--white);
                span {
                  display: inline-block;
                  vertical-align: top;
                  color: #60fdb9;
                  margin-right: 10px;
                  margin-top: 4px;
                }
              }
              button {
                background: rgba(255, 255, 255, 0.2);
                color: var(--white);
              }
            }
          }
        }
      }

      > .subscribe_wrap {
        > div {
          width: 100%;
          border-radius: 10px;
          height: 45px;
          background-color: var(--grey1);
          color: var(--grey5);
          font-size: 18px;
          font-weight: 700;
          font-size: 18px;
          line-height: 140%;
          padding: 10px 20px;
          box-sizing: border-box;
        }
        .subscribe {
          margin-bottom: 0;
          @media screen and (max-width: 809px) {
            border-bottom: 1px solid var(--grey3);
          }
          li {
            display: inline-block;
            vertical-align: middle;
            width: 282px;
            height: 100px;
            padding: 16px 20px;
            box-sizing: border-box;
            font-weight: 500;
            font-size: 18px;
            line-height: 140%;
            border-right: 1px solid var(--grey3);
            &:last-child {
              border-right: none;
            }
            h6 {
              display: none;
            }
            @media screen and (max-width: 1199px) and (min-width: 810px) {
              width: 189.5px;
              &.big {
                height: 139px;
                img {
                  margin-top: 43px;
                }
              }
              &.big2 {
                height: 120px;
                img {
                  margin-top: 32px;
                }
              }
            }
            @media screen and (max-width: 809px) {
              width: 33%;
              vertical-align: top;
              border-right: none;
              h6 {
                text-align: center;
                font-weight: 500;
                font-size: 16px;
                line-height: 140%;
                color: #28323c;
                margin-bottom: 11px;
                display: block;
              }
              &:nth-child(1) {
                display: block;
                width: 100%;
              }
            }
            span {
              h5 {
                color: var(--grey7);
                font-size: 18px;
                line-height: 140%;
                margin-bottom: 6px;
                @media screen and (max-width: 1199px) and (min-width: 810px) {
                  letter-spacing: -0.08em;
                  word-break: keep-all;
                }
              }
              p {
                color: var(--grey6);
                font-weight: 400;
                font-size: 14px;
                line-height: 140%;
                @media screen and (max-width: 1199px) and (min-width: 810px) {
                  word-break: keep-all;
                  letter-spacing: -0.11em;
                }
              }
            }
            img {
              width: 22px;
              height: 22px;
              display: block;
              margin: 0 auto;
              margin-top: 15px;
              @media screen and (max-width: 809px) {
                margin-top: 0;
              }
            }
          }
        }
      }
    }
    &:nth-child(8) {
      background: linear-gradient(
        180deg,
        #6c38ff 0%,
        rgba(108, 56, 255, 0.7) 100%
      );
      height: 540px;
      position: relative;
      @media screen and (max-width: 1199px) and (min-width: 810px) {
        height: 810px;
      }
      @media screen and (max-width: 809px) {
        width: 100%;
        height: 773px;
      }
      > div {
        padding-top: 170px;
        width: 1128px;
        margin: 0 auto;
        @media screen and (max-width: 1199px) and (min-width: 810px) {
          height: 773px;
          width: 744px;
        }
        @media screen and (max-width: 809px) {
          width: 90%;
          padding-top: 100px;
        }
        h1 {
          text-align: left;
          color: var(--white);
          font-weight: 700;
          font-size: 40px;
          line-height: 150%;
          margin-bottom: 50px;
          @media screen and (max-width: 809px) {
            font-size: 24px;
            width: 100%;
            margin-bottom: 18px;
          }
        }
        button {
          margin-bottom: 165px;
          font-weight: 700;
          font-size: 18px;
          line-height: 140%;
          text-align: center;
          color: #6c38ff;
          padding: 12px 15px;
          box-sizing: border-box;
          background: var(--white);
          border-radius: 6px;
        }
      }
      img {
        width: 647px;
        height: 646px;
        position: absolute;
        right: -10px;
        bottom: -107px;
        @media screen and (max-width: 809px) {
          width: 483px;
          height: 482px;
          right: -40%;
          bottom: -1%;
        }
      }
    }
  }
`;

// SettingContainer
export const SettingContainer = styled.div`
  position: relative;
  padding-top: 60px;
  /* min-height: calc(100vh - 38px); */
  width: 100%;

  > div {
    position: relative;
    width: 308px;
    margin: 0 auto;
    h4 {
      margin-bottom: 24px;
      font-size: 26px;
      line-height: 140%;

      color: var(--text);
      font-weight: 700;
      margin-top: 40px;
    }
    h5 {
      font-size: 16px;
      line-height: 140%;

      color: var(--text);
      margin-bottom: 14px;
    }

    > div {
      position: relative;
      margin-bottom: 30px;
      .css-fqp3gx-control {
        img {
          width: 10px;
        }
      }
      .connect_user {
        background: #f7f8f9;
        border-radius: 8px;
        padding: 14px 16px;
        margin-top: 14px;
        .children {
          cursor: inherit;
        }
      }

      h6 {
        font-size: 14px;
        line-height: 140%;

        color: var(--text);
        margin-bottom: 6px;
        font-weight: 400;
      }
      input[type="text"] {
        width: 308px;
        height: 48px;
        border-radius: 8px;
        padding: 14px 16px;
        border: 1px solid var(--grey3);
        font-size: 14px;
        &.grey_input {
          background-color: var(--grey1);
          &:focus {
            border: 1px solid var(--grey3);
          }
        }
        &:focus {
          border: 1px solid var(--main);
        }
      }
      input[type="number"] {
        width: 308px;
        height: 48px;
        border-radius: 8px;
        padding: 14px 16px;
        border: 1px solid var(--grey3);
        display: inline-block;
        vertical-align: middle;
        margin-right: 6px;
        font-size: 14px;
        &.grey_input {
          background-color: var(--grey1);
        }
      }
      .profile_picture {
        width: 200px;
        height: 200px;
        background: var(--grey1);
        border-radius: 6px;
        li {
          width: 200px;
          height: 200px;
          position: relative;
          cursor: pointer;
          z-index: 9;
          > img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
          label {
            cursor: pointer;
            padding-top: 75px;
            display: block;
            width: 100%;
            height: 100%;
            .picture_icon {
              width: 24px !important;
              height: 24px !important;
              display: block;
              margin: 0 auto;
              margin-bottom: 4px;
            }
            p {
              text-align: center;
              font-size: 14px;
              color: var(--grey6);
              margin-top: 8px;
            }
          }
          // 호버했을시 이미지 삭제(휴지통)
          &:hover {
            transition-duration: 0.3s;
            span {
              display: block;
            }
          }
          span {
            display: none;
            background: linear-gradient(
              0deg,
              rgba(0, 0, 0, 0.5),
              rgba(0, 0, 0, 0.5)
            );
            border-radius: 6px;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            text-align: center;
            font-size: 14px;
            color: var(--white);
            img {
              width: 20px;
              height: 20px;
              display: block;
              margin: 0 auto;
              margin-top: 75px;
              margin-bottom: 8px;
            }
          }
        }
      }
      span {
        font-size: 14px;
        color: var(--grey5);
        display: inline-block;
        vertical-align: middle;
        &.parents {
          width: 100%;
          background-color: var(--grey1);
          border-radius: 8px;
          box-sizing: border-box;
          display: block;
          margin-bottom: 10px;
          cursor: pointer;
          &:last-child {
            margin-bottom: 0;
          }
          &.children {
            cursor: inherit;
          }

          h6 {
            font-weight: 500;
            font-size: 16px;
            line-height: 140%;
            color: var(--text);
            display: inline-block;
            vertical-align: middle;
          }
          span {
            display: inline-block;
            padding: 4px 6px;
            background-color: var(--grey3);
            color: var(--grey6);
            border-radius: 4px;
            width: auto;
            vertical-align: middle;
            margin-bottom: 6px;
          }
          p {
            font-size: 14px;
            line-height: 140%;
            color: var(--grey5);
          }
          img {
            width: 40px;
            height: 40px;
            border-radius: 100%;
            display: inline-block;
            vertical-align: middle;
            margin-right: 12px;
          }
          > div {
            display: inline-block;
            vertical-align: middle;
          }
        }
      }
      .bye_user {
        margin-bottom: 108px;
        margin-top: 20px;
        span {
          border-bottom: 1px solid var(--grey5);
          display: block;
          cursor: pointer;
          width: fit-content;
        }
      }
      button {
        height: 42px;
        font-size: 14px;
        font-weight: 600;
        border-radius: 6px;
        box-sizing: border-box;
        width: 100%;
        &.mt10 {
          margin-top: 10px;
        }
        &.change_button {
          color: var(--main);
          border: 1px solid var(--main);
        }
        &.save_button {
          color: var(--white);
          background-color: var(--main);
          display: inline-block;
          vertical-align: middle;
        }
      }
    }
    .subscription_info {
      font-size: 16px;
      padding: 16px;
      border-radius: 8px;
      background-color: var(--grey1);
      color: var(--text);
      margin-bottom: 30px;
      box-sizing: border-box;
      h5 {
        display: inline-block;
        margin: 0;
      }
      p {
        display: inline-block;
        float: right;
        font-size: 14px;
        color: var(--main);
        margin: 0;
        line-height: 24px;
      }
    }
  }
`;

// Radiostyle
export const Radiostyle = styled.div`
  margin-top: 16px;
  margin-bottom: 30px;
  input[type="radio"] {
    display: inline-block;
    margin-right: 10px;
    position: relative;
  }
  input[type="radio"],
  input[type="radio"]:checked {
    appearance: none;
    cursor: pointer;
    padding: 0;
  }
  input[type="radio"] {
    width: 20px;
    height: 20px;
    vertical-align: middle;
    background-color: var(--white);
    border: 1px solid var(--grey4);
    color: var(--grey4);
    margin-right: 8px;
    cursor: pointer;
    border-radius: 100%;
    &:checked {
      background-color: var(--white);
      border: 6px solid var(--main);
      color: var(--grey4);
    }
  }
  label {
    margin-right: 23px;
    cursor: pointer;
    font-size: 14px;
    color: var(--text);
  }
  > div {
    margin-bottom: 14px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

// BasicButton
export const BasicButton = styled.button`
  cursor: pointer;
  font-size: 14px;
  text-align: center;
  padding: 7px 17px 8px;
  color: var(--text);
  padding: 8px 10px;
  border-radius: 6px;
  transition: background-color 0.1s ease-in-out;
  line-height: 140%;
  box-sizing: border-box;

  &.main {
    padding: 11px 16px;
    background-color: var(--main);
    color: var(--white);
  }
  &.main2 {
    padding: 11px 16px;
    background-color: #a788ff;
    color: var(--white);
  }
  &.grey2 {
    padding: 11px 16px;
    background-color: var(--grey2);
    color: var(--grey6);
  }
  &.alert_ok {
    width: 138px;
    height: 42px;
    background-color: var(--main);
    color: var(--white);
  }
`;

// Subscribe
export const SubscribeBox = styled.div`


  .subscribe_main {
    width: 1128px;
    margin: 0 auto;
    margin-top: 120px;
    @media screen and (max-width: 1199px) and (min-width: 810px) {
      width: 758px;
    }
    @media screen and (max-width: 809px) {
      width: 90%;
    }
    > h4 {
      font-weight: 700;
      font-size: 30px;
      line-height: 140%;
      margin-bottom: 20px;
      @media screen and (max-width: 809px) {
        font-size: 18px;
      }
    }
    > ul {
      margin-bottom: 20px;
      &:nth-child(2) {
        li {
          display: inline-block;
          vertical-align: middle;
          margin-right: 20px;
          &:nth-child(1) {
            img {
              width: 267px;
              height: 209px;
              @media screen and (max-width: 1199px) and (min-width: 810px) {
                width: 169px;
                height: 169px;
              }
            }
            @media screen and (max-width: 809px) {
              width: 100%;
              display: block;
              img {
                display: block;
                margin: 0 auto;
              }
            }
          }
          &:nth-child(2),
          &:nth-child(3),
          &:nth-child(4) {
            width: 267px;
            height: 174px;
            background: var(--white);
            box-shadow: 0px 19px 54px rgba(195, 199, 209, 0.28);
            border-radius: 20px;
            padding: 20px 12px;
            box-sizing: border-box;
            font-weight: 700;
            line-height: 140%;
            text-align: center;
            position: relative;
            @media screen and (max-width: 1199px) and (min-width: 810px) {
              width: 174.5px;
              height: 174px;
            }
            @media screen and (max-width: 809px) {
              width: 100%;
              display: block;
              margin: 0 auto;
              margin-bottom: 26px;
            }
            h4 {
              font-size: 18px;
              color: #646f7c;
              display: block;
              margin-bottom: 18px;
              line-height: 140%;
            }
            h3 {
              font-size: 22px;
              margin-bottom: 18px;
              line-height: 140%;
            }
            button {
              width: 89px;
              height: 42px;
              background: #ffffff;
              border: 1px solid var(--main);
              border-radius: 6px;
              color: var(--main);
              font-weight: 500;
              font-size: 16px;
              line-height: 140%;
            }
            > img {
              width: 32px;
              height: 20px;
              display: block;
              position: absolute;
              left: 50%;
              transform: translateX(-50%);
              bottom: -17px;
            }
          }
          &:last-child {
            margin-right: 0;
            background-color: var(--main);
            h4 {
              color: var(--white);
            }
            h3 {
              color: var(--white);
              span {
                display: inline-block;
                vertical-align: top;
                color: #60fdb9;
                margin-right: 10px;
                margin-top: 4px;
              }
            }
            button {
              background: rgba(255, 255, 255, 0.2);
              color: var(--white);
            }
          }
        }
      }
    }

    > .subscribe_wrap {
      > div {
        width: 100%;
        border-radius: 10px;
        height: 45px;
        background-color: var(--grey1);
        color: var(--grey5);
        font-size: 18px;
        font-weight: 700;
        font-size: 18px;
        line-height: 140%;
        padding: 10px 20px;
        box-sizing: border-box;
      }
      .subscribe {
        margin-bottom: 0;
        @media screen and (max-width: 809px) {
          border-bottom: 1px solid var(--grey3);
        }
        li {
          display: inline-block;
          vertical-align: middle;
          width: 282px;
          height: 100px;
          padding: 16px 20px;
          box-sizing: border-box;
          font-weight: 500;
          font-size: 18px;
          line-height: 140%;
          border-right: 1px solid var(--grey3);
          &:last-child {
            border-right: none;
          }
          h6 {
            display: none;
          }
          @media screen and (max-width: 1199px) and (min-width: 810px) {
            width: 189.5px;
            &.big {
              height: 139px;
              img {
                margin-top: 43px;
              }
            }
            &.big2 {
              height: 120px;
              img {
                margin-top: 32px;
              }
            }
          }
          @media screen and (max-width: 809px) {
            width: 33%;
            vertical-align: top;
            border-right: none;
            h6 {
              text-align: center;
              font-weight: 500;
              font-size: 16px;
              line-height: 140%;
              color: #28323c;
              margin-bottom: 11px;
              display: block;
            }
            &:nth-child(1) {
              display: block;
              width: 100%;
            }
          }
          span {
            h5 {
              color: var(--grey7);
              font-size: 18px;
              line-height: 140%;
              margin-bottom: 6px;
              @media screen and (max-width: 1199px) and (min-width: 810px) {
                letter-spacing: -0.08em;
                word-break: keep-all;
              }
            }
            p {
              color: var(--grey6);
              font-weight: 400;
              font-size: 14px;
              line-height: 140%;
              @media screen and (max-width: 1199px) and (min-width: 810px) {
                word-break: keep-all;
                letter-spacing: -0.11em;
              }
            }
          }
          img {
            width: 22px;
            height: 22px;
            display: block;
            margin: 0 auto;
            margin-top: 15px;
            @media screen and (max-width: 809px) {
              margin-top: 0;
            }
          }
        }
      }
    }
  }
`;

// QuestionSt  지주 묻는 질문
export const QuestionSt = styled.div`
  width: 936px;
  margin: 0 auto;
  margin-bottom: 14px;
  min-height: ${({ open }) => (open ? "100%" : "61px")};
  max-height: ${({ open }) => (open ? "100%" : "61px")};
  position: relative;
  overflow: hidden;
  @media screen and (max-width: 1199px) and (min-width: 810px) {
    width: 730px;
  }
  @media screen and (max-width: 809px) {
    width: 90%;
    min-height: ${({ open }) => (open ? "100%" : "70px")};
    // max-height: ${({ open }) => (open ? "100%" : "100px")};
  }

  .qustion_box {
    @media screen and (max-width: 490px) {
      &.padding_box {
        div {
          padding: 10px 18px 10px 10px;
        }
      }
    }

    > div {
      position: relative;
      background-color: var(--grey2);
      border-radius: 10px;
      padding: 18px;
      color: var(--grey7);
      cursor: pointer;
      &:hover,
      &:focus {
        background-color: var(--grey3);
      }
      p {
        display: inline-block;
        vertical-align: middle;
        font-weight: 400;
        font-size: 18px;
        line-height: 140%;
        font-weight: 700;
        &.lh190 {
          line-height: 190%;
        }
        @media screen and (max-width: 809px) {
          width: calc(100% - 54px);
          font-size: 16px;
          /* line-height: 173%; */
          letter-spacing: -0.08em;
          word-break: keep-all;
        }
      }
      img {
        width: 20px;
        height: 20px;
        vertical-align: middle;
        display: inline-block;
        margin-right: 6px;
        @media screen and (max-width: 809px) {
          width: 16px;
          height: 16px;
          vertical-align: top;
          margin-top: 2px;
        }
        &.mt7 {
          margin-top: 7px;
        }
        &.arrow {
          width: 22px;
          height: 22px;
          float: right;
          // transform: rotate(90deg);
          transform: ${({ open }) =>
            open ? "rotate(-90deg)" : " rotate(90deg)"};
          @media screen and (max-width: 809px) {
            width: 16px;
            height: 16px;
          }
        }
      }
      > div {
        ul {
          li {
            font-weight: 500;
            padding: 16px 20px;
            img {
              width: 30px;
              height: 30px;
              margin-right: 10px;
            }
            p {
              font-size: 16px;
              font-weight: 400;
            }
          }
        }
      }
    }
    .answer {
      display: flex;
      padding: 18px;
      cursor: auto;
      line-height: 190%;
      margin-top: 16px;
      p {
        font-weight: 400;
        word-break: break-all;
      }
      &:hover,
      &:focus {
        background-color: var(--grey2);
      }
      img {
        margin-top: 4px;
      }
    }
  }
`;

// StyleContainer
export const StyleContainer = styled.div`
  width: 100%;
  h1 {
    width: 936px;
    margin: 0 auto;
    margin-top: 140px;
    text-align: left;
    margin-bottom: 50px;
    font-size: 30px;
    line-height: 140%;

    @media screen and (max-width: 1199px) and (min-width: 810px) {
      width: 730px;
    }
    @media screen and (max-width: 809px) {
      width: 90%;
      font-size: 26px;
    }
  }
  .question_nav {
    width: 936px;
    margin: 0 auto;
    margin-bottom: 42px;
    @media screen and (max-width: 1199px) and (min-width: 810px) {
      width: 730px;
    }
    @media screen and (max-width: 809px) {
      width: 90%;
    }
    li {
      padding: 10px 12px;
      display: inline-block;
      cursor: pointer;
      border-radius: 8px;
      font-weight: 500;
      font-size: 20px;
      line-height: 140%;
      padding: 10px 18px;
      background: #f7f8f9;
      color: #a2a7b1;
      margin-right: 10px;
      &.on {
        color: var(--white);
        background: #6c38ff;
      }
      @media screen and (max-width: 809px) {
        font-size: 16px;
      }
    }
  }




   .userNewsList{

      width: 900px;
      margin: 0 auto;
      padding:180px 20px 20px 20px;

    .header{

      padding-bottom:50px;
      margin-bottom:10px;
      border-bottom:1px solid #ccc;
      display:flex;
      align-items:center;

      .cont-box{
        margin-left:25px;
        

        .intro{
          font-size:0.83rem;
          color: #737373;
        }
      }


      .profile{
        width:100px;
        height:100px;
        border-radius:50%;
        border:1px solid #ccc;
      }
      .nick_name{
        font-size:1.25rem;
        margin-bottom:9px;
      }
    }

 

    .bottom{
      li{
      cursor:pointer; 
      padding : 25px 0;
      display:flex;
      border-bottom: 1px solid #eaeaea;

      &:hover{
        background:#f6f6f6;
      }
        
        .img-box{
          img{
              width: 250px;
              height: 180px;
              border-radius: 10px;
              object-fit: cover;
          }
        }

      }

      .content-box{
        margin-left:25px;

        .title{
          font-size:16px;
          font-weight:600;
          margin-top:15px;
          margin-bottom:15px;
        }

        .content{
          font-size:14px;
          margin-bottom:20px;
          line-height:1.7;
          display: -webkit-box;  
          -webkit-box-orient: vertical; 
          -webkit-line-clamp: 3;        
          overflow: hidden;               
          text-overflow: ellipsis;   
          height:75px;
          color:#808080;
        }

        .user-box{
        
        font-size:15px;
        display: flex;
        align-items: center;

         .username{
            font-weight:bold;
            margin-right:10px;
            color:#808080;            
		      }

        .profile{
              width: 29px;
              height: 29px;
              border: 1px solid #ccc;
              margin-right:9px;
              border-radius: 50%;
              top: 1px;
              position: relative;
            }

        .date{
          color:#888;
          font-size:14px;
          margin-top:3px;
        }

        .iconbox{
          display: inline-block;
          vertical-align: middle;
              margin-left: 8px;
    margin-top: 3px;

          span{
              display: inline-block;

              img:nth-child(1) {
                  width: 21px;
                  height: 18px;
              }

          }


        }

        
        
        }
      }
        


    }
  }


  
  @media screen and (max-width: 1199px) and (min-width: 810px) {
    .userNewsList{
      width:720px;

        .bottom{
        li{
          margin-bottom:35px;
          }
        }
    }
  }

    @media screen and (max-width: 809px) {
      .userNewsList{

       padding-top:100px;

        width:90%;

        .header{        
          padding-bottom:30px;
          margin-bottom:30px;

          .profile{
            width:80px;
            height:80px;
          }
        }

        .bottom{
          li{
            display:block;
            margin-bottom:30px; 
          }
          .content-box{
            margin-left:0;
          }

          .img-box{
            margin-bottom:8px;
            img{
              width:100% !important;
            }
          }
        }
      }
    }


  .question_footer {
    color: var(--white);
    height: 480px;
    background: #ebe3ff;
    margin-top: 350px;
    width: 100%;
    @media screen and (max-width: 1199px) and (min-width: 810px) {
      margin-top: 158px;
    }
    @media screen and (max-width: 809px) {
      margin-top: 120px;
    }
    > div {
      width: 70%;
      margin: 0 auto;
      position: relative;
      height: 480px;
      @media screen and (max-width: 1199px) and (min-width: 810px) {
        width: 730px;
      }
      @media screen and (max-width: 809px) {
        width: 90%;
      }
      div {
        &:nth-child(1) {
          width: 350px;
          height: 185px;
          display: inline-block;
          padding-top: 148px;
          h3 {
            font-weight: 700;
            font-size: 30px;
            line-height: 140%;
            color: #374553;
            margin-bottom: 10px;
          }
          p {
            font-weight: 400;
            font-size: 16px;
            line-height: 160%;
            color: #374553;
          }
          .btns {
            button {
              margin-top: 32px;
              font-weight: 500;
              font-size: 18px;
              line-height: 140%;
              background: #ffffff;
              border-radius: 6px;
              padding: 12px 15px;
              color: var(--main);
              &:nth-child(1) {
                margin-right: 8px;
              }
            }
          }
          @media screen and (max-width: 809px) {
            width: 100%;
            margin: 0 auto;
            height: auto;
            text-align: center;
            padding-top: 88px;
            position: relative;
            z-index: 9;
            h3 {
              font-size: 22px;
            }
            p {
              font-size: 15px;
            }
            .btns {
              button {
                font-size: 16px;
              }
            }
          }
        }
        &:nth-child(2) {
          position: absolute;
          right: 0;
          bottom: 0;
          display: block;
          @media screen and (max-width: 1199px) and (min-width: 810px) {
            right: 11%;
          }
          @media screen and (max-width: 809px) {
            left: 0;
            bottom: -10%;
          }
          img {
            width: 289px;
            height: 280px;
          }
        }
      }
      .talk {
        position: absolute;
        top: 40%;
        right: -11%;
        width: 100px;
        height: 90px;
        display: block;
        @media screen and (max-width: 1199px) and (min-width: 810px) {
          right: 0%;
        }
        @media screen and (max-width: 809px) {
          right: -10px;
          top: 51%;
          width: 90px;
          height: 85px;
        }
      }
    }
  }
`;

// LogoBox
export const LogoBox = styled.div`
  div {
    font-size: 26px;
    font-weight: 700;
    position: relative;
    margin: 0 auto;
    margin-bottom: 36px;
    padding-top: 160px;
    width: 512px;
    box-sizing: border-box;
    img {
      width: 119px;
      height: 35px;
      object-fit: contain;
      margin: 0 auto;
      display: block;
      margin-bottom: 32px;
    }
  }
  h4 {
    font-weight: 700;
    font-size: 26px;
    line-height: 140%;
    text-align: center;

    margin-bottom: 8px;
    display: block;
    color: var(--text);
  }
  h5 {
    font-size: 16px;
    line-height: 140%;
    text-align: center;
    display: block;

    color: var(--text);
    font-weight: 400;
  }
  h6 {
    margin-bottom: 6px;
    font-weight: 400;
    font-size: 12px;
    line-height: 140%;
    color: var(--grey6);
  }
`;
// InputBox
export const InputBox = styled.div`
  width: 308px;
  margin: 0 auto;
  margin-bottom: 300px;
  div {
    margin-bottom: 30px;
    h6 {
      margin-bottom: 6px;
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      color: var(--grey6);
    }
    p {
      text-align: right;
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      color: var(--grey7);
      margin-top: 14px;
      cursor: pointer;
    }

    .error_tag {
      color: var(--red);
      font-size: 14px;
      line-height: 140%;
      margin-top: 6px;
      text-align: left;
    }
    .safe_tag {
      color: var(--main);
      font-size: 14px;
      line-height: 140%;
      margin-top: 6px;
      text-align: left;
    }
    .input_error {
      border: 1px solid var(--red);
    }
    input {
      padding-top: 3px;
      width: 100%;
      height: 48px;
      font-size: 14px;
      border: 1px solid var(--grey3);
      transition: border 0.1s ease-in-out, background-color 0.1s ease-in-out;
      padding: 14px 16px;
      border-radius: 8px;
      &::placeholder {
        font-size: 14px;
        color: var(--grey5);
      }
      &.grey_input {
        background-color: var(--grey1);
        border: 1px solid var(--grey3);
      }
      &.certification_input {
        margin: 10px 0;
      }
    }
    .certification_button {
      width: 308px;
      height: 42px;
      background: #ffffff;
      border: 1px solid #6c38ff;
      border-radius: 6px;
      display: block;
      margin-bottom: 30px;
      color: var(--main);
      margin-top: 30px;
    }
    .btns {
      width: 100% !important;
      button {
        width: 100% !important;
        &:nth-child(1) {
          height: 42px;
          background: #ffffff;
          border: 1px solid var(--main);
          border-radius: 6px;
          text-align: center;
          color: var(--main);
          display: block;
        }
        &:nth-child(2) {
          width: 100%;
          height: 42px;
          background: #a788ff;
          border-radius: 6px;
          color: var(--white);
          display: block;
          margin-left: 0 !important;
          margin-top: 40px;
        }
      }
    }
  }
  .find_password {
    text-align: right;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    text-align: right;

    color: #646f7c;
    cursor: pointer;
  }
  .btns {
    width: 100% !important;
    margin-top: 40px;

    button {
      width: 100% !important;
    }
  }
`;

// CertificationBox
export const CertificationBox = styled.div`
  > div {
    margin: 0 auto;
    padding: 20px;
    width: 308px;
    height: 65px;
    background: #ffffff;
    border: 1px solid #6c38ff;
    border-radius: 10px;
    box-sizing: border-box;
    line-height: 140%;
    cursor: pointer;
    margin-top: 30px;
    margin-bottom: 14px;
    color: var(--main);
    font-weight: 600;

    &:hover {
      background-color: var(--grey1);
    }
    &:last-child {
      margin-bottom: 0;
    }
    img {
      width: 16px;
      height: 16px;
      float: right;
      display: inline-block;
      vertical-align: middle;
      margin-top: 3px;
    }
    span {
      &.certification_finish {
        padding: 4px 10px;
        width: 46px;
        height: 28px;
        background: rgba(86, 197, 0, 0.2);
        border-radius: 100px;
        font-size: 14px;
        line-height: 140%;

        color: #56c500;
        margin-left: 30px;
        font-weight: 400;
      }
      &.parents_finish {
        margin-left: 130px;
      }
    }
  }
`;
